<template>
    <div>private_sms</div>
</template>

<script>
export default {
    name: "VideoVue22PrivateSms",

    data() {
        return {};
    },

    mounted() {},

    methods: {},
};
</script>

<style lang="scss" scoped>
</style>